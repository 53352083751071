import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import { createAction } from '@wix/communities-blog-client-common';
import { createPromisifiedActionsService } from '../../actions-promisifier/create-promisified-actions-service';
import { withPromisifiedOpenModal } from '../../modals/framework/store/modal-actions';
import { getUpdatedState } from '../../services/state-optimizer/change-detector';
import {
  isDebug,
  isProduction,
  isEditor,
} from '../../store/basic-params/basic-params-selectors';

const SET_STATE = 'root/SET_STATE';
const setState = createAction(SET_STATE);

const SET_HOST = 'root/SET_HOST';
const setHost = createAction(SET_HOST);

const root = (state = {}, { type, payload }) => {
  switch (type) {
    case SET_STATE:
      return { ...state, state: payload };
    case SET_HOST:
      return { ...state, host: payload };
    default:
      return state;
  }
};

export const withReduxStore = (AppComponent) => {
  class WithReduxStore extends React.Component {
    store = null;
    stateVersions = null;
    promisifiedActionsService = createPromisifiedActionsService();
    controllerId = null;

    componentDidUpdate(prevProps) {
      if (prevProps.isSSR !== this.props.isSSR && this.markWorkerAsReady) {
        this.markWorkerAsReady();
      }
    }

    getWorkerReady = () => {
      this.workerReady =
        this.workerReady ||
        new Promise((resolve) => {
          if (!this.props.isSSR) {
            resolve();
            this.markWorkerAsReady = () => {};
          } else {
            this.markWorkerAsReady = resolve;
          }
        });
      return this.workerReady;
    };

    patchState = (state, host) => {
      state.appSettings = {
        ...state.appSettings,
        colorPresets: host.style.siteColors,
        textPresets: host.style.siteTextPresets,
        style: host.style.styleParams,
      };
    };

    render() {
      const {
        state,
        stateVersions,
        actions,
        actionsPromisified,
        host,
        controllerId,
      } = this.props;

      if (!this.controllerId) {
        this.controllerId = controllerId;
      }

      if (!this.store) {
        const connectedActionsPromisified = Object.keys(
          actionsPromisified,
        ).reduce((wrapped, actionName) => {
          wrapped[actionName] =
            this.promisifiedActionsService.usePromisifiedAction(
              actionsPromisified[actionName],
              state,
            );
          return wrapped;
        }, {});

        this.stateVersions = stateVersions;
        this.patchState(state, host);
        this.store = createStore(root, {
          state,
          actions: {
            ...actions,
            ...connectedActionsPromisified,
            openModal: withPromisifiedOpenModal(actions.openModal),
          },
          host,
          workerReady: this.getWorkerReady(),
        });
      } else {
        const currentState = this.store.getState().state;
        const currentStateVersions = this.stateVersions;

        const updatedState = getUpdatedState({
          currentState,
          currentVersions: currentStateVersions,
          newState: state,
          newVersions: stateVersions,
          currentControllerId: this.controllerId,
          newControllerId: controllerId,
        });
        this.stateVersions = stateVersions;

        if (isEditor(updatedState)) {
          this.patchState(updatedState, host);
          this.store.dispatch(setState(updatedState));
          this.store.dispatch(setHost(host));
        } else {
          if (
            updatedState.appSettings &&
            !updatedState.appSettings.colorPresets
          ) {
            this.patchState(updatedState, host);
          }
          this.store.dispatch(setState(updatedState));
        }
        this.promisifiedActionsService.resolvePromisifiedActions(updatedState);
      }

      if (this.controllerId < controllerId) {
        this.controllerId = controllerId;
      }

      if (
        isDebug(this.store.getState().state) ||
        !isProduction(this.store.getState().state)
      ) {
        console.log('AppRoot', this.props);
      }

      return (
        <Provider store={this.store}>
          <AppComponent />
        </Provider>
      );
    }
  }

  WithReduxStore.propTypes = {
    state: PropTypes.object.isRequired,
    host: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    actionsPromisified: PropTypes.object.isRequired,
    stateVersions: PropTypes.object,
    controllerId: PropTypes.number,
  };

  return WithReduxStore;
};
