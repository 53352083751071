import { createContext, useContext } from 'react';

export type WithResponsiveContext = {
  rootWidth: number | undefined;
};

export const useResponsiveContext = () => {
  return useContext(context);
};

const context = createContext<WithResponsiveContext>({
  rootWidth: undefined,
});

export default context;
